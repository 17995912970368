@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");

/* common */
body {
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  letter-spacing: -0.02rem;
}

/* header */
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  padding-top: 40px;
  z-index: 10;
}
.header-inner .header-top {
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
  font-size: 1.1rem;
  border-bottom: 1px solid #eee;
}
.header-inner .header-top h3 {
  font-weight: 500;
}
.header-inner .header-bottom {
  padding: 10px 40px;
  font-size: 1rem;
}

/* main */
.main-inner {
  padding: 10rem 2.5rem 2.5rem 2.5rem;
  display: flex;
  justify-content: space-between;
}
.main-inner .main-left {
  width: 45%;
}
.main-inner .main-left h2 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
}
.main-inner .main-left h1 {
  font-size: 5rem;
  font-weight: 900;
  line-height: 140%;
}
.main-inner .main-left p {
  width: 100%;
  margin-top: 60px;
  font-size: 1.3rem;
  line-height: 180%;
  font-weight: 400;
  color: #333;
}
.main-inner .main-right {
  width: 40%;
  margin-left: 20px;
  object-fit: contain;
  position: relative;
}
.main-inner .main-right img {
  object-fit: contain;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  position: absolute;
  right: 20%;
  z-index: 1;
}
.main-inner .main-right::after {
  content: "";
  display: block;
  width: 100%;
  height: 110%;
  background-color: #f8f8f8;
  position: absolute;
  right: 0px;
  top: 100px;
  border-radius: 20px;
}

/* about */
.about-cont {
  margin-bottom: 80px;
}
.about-inner {
  padding: 0 40px;
  margin-top: 2.5rem;
}
.about-inner .about-txt {
  background-color: #fff;
}
.about-inner .about-txt h3 {
  color: #a5a5a5;
  margin-bottom: 30px;
}
.about-inner .about-txt h3 strong {
  font-weight: 700;
}
.about-inner .about-txt p {
  color: #666;
  line-height: 190%;
}
.about-inner .about-txt p:last-child {
  margin-top: 10px;
}
.about-inner .about-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.about-inner .about-bottom > div {
  width: 48%;
  margin-top: 2.5rem;
}
.about-inner .about-bottom > div h3 {
  color: #a5a5a5;
  margin-bottom: 35px;
}
.about-inner .about-bottom > div ul {
  width: 100%;
}
.about-inner .about-bottom > div ul li {
  color: #666;
  margin-bottom: 10px;
}
.about-inner .about-bottom > div ul li span {
  display: inline-block;
  border-radius: 3px;
  background: rgba(135, 131, 120, 0.15);
  color: rgba(120, 119, 116, 1);
  padding: 0 10px;
  line-height: 30px;
  text-align: center;
  margin-right: 10px;
  min-width: 50px;
}
.about-inner .about-exp {
  padding-left: 2%;
}
.about-inner .about-edu ul {
  border-right: 1px solid #a5a5a5;
  margin-right: 2%;
}

/* works */
.works-cont {
  background-color: #f8f8f8;
}
.works-inner {
  padding: 80px 40px;
  display: flex;
}

.works-inner .works {
  width: 100%;
  background-color: #fff;
  margin-right: 30px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 4px 12px 30px 6px rgba(0, 0, 0, 0.09);
  transition: all linear 0.5s;
  position: relative;
}
.works-inner > a {
  margin-right: 30px;
  width: 25%;
}
.works-inner > a:last-child {
  margin-right: 0px;
}
.works-inner .works:hover {
  top: -8px;
  box-shadow: 10px 10px 15px 6px rgba(0, 0, 0, 0.09);
}
.works-inner .works:nth-child(2) {
  transition-delay: 0.1s;
}
.works-inner .works:nth-child(3) {
  transition-delay: 0.2s;
}
.works-inner .works .works-title {
  padding: 25px;
}
.works-inner .works .works-title span {
  padding: 5px 15px;
  background-color: #eee;
  border-radius: 15px;
  font-size: 0.9rem;
}
.works-inner .works .works-title h3 {
  font-size: 1.8rem;
  font-weight: 600;
  margin-top: 25px;
  line-height: 130%;
  height: 60px;
}
.works-inner .works .works-title p {
  font-size: 1rem;
  color: #666;
  margin-top: 40px;
}
.works-inner .works .works-img {
  display: flex;
  width: 100%;
  height: 219px;
  text-align: center;
  background-color: #343240;
}
.works-inner .works .works-img img {
  width: 100%;
  object-fit: contain;
}

/* contact */
.contact-cont {
  margin: 80px 40px;
}
.contact-cont .contact-inner {
  display: flex;
  justify-content: space-between;
  padding: 28px 36px;
  border-radius: 14px;
  background-color: #fff;
  text-align: center;
  box-shadow: 2px 5px 40px 0 rgba(0, 0, 0, 0.08);
  height: 100px;
  align-items: center;
}
.contact-inner p {
  font-size: 2rem;
  font-weight: 700;
  padding: 0 15px 0 5px;
}
.contact-inner .mobile-txt {
  display: none;
}
.contact-inner a {
  line-height: 40px;
  margin-top: 11px;
}
.contact-inner a:after {
  display: block;
  content: "";
  border-bottom: 1px solid #000;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  transform-origin: 0 50%;
}
.contact-inner a:hover:after {
  transform: scaleX(1);
}
.contact-inner .arrowicon {
  margin-right: 10px;
}

/* projects */
.works-cont.projects-cont {
  background-color: #ffffff;
}

.works-cont.projects-cont .works-inner {
  justify-content: center;
}

/* footer */
footer {
  padding-bottom: 80px;
}
footer .copyright {
  margin: 0 auto;
  color: #a5a5a5;
  text-align: center;
}
footer .copyright p {
  font-size: 1rem;
}
footer .copyright p span {
  font-weight: 700;
}
footer .copyright p.date {
  color: #ccc;
  margin-top: 15px;
}

/* work */
.work-detail {
  width: 100%;
  padding-top: 180px;
}
.work-detail .work-title {
  width: 630px;
  padding: 0 40px;
  text-align: center;
  margin: 0 auto;
}
.work-detail .work-title h3 {
  color: #666;
}
.work-detail .work-title h2 {
  font-size: 45px;
  font-weight: 600;
  margin-top: 30px;
  line-height: 140%;
}
.work-detail .work-title .sub-info {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
}
.work-detail .work-title .sub-info .date {
  color: #333;
}
.work-detail .work-title .sub-info .date::after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 16px;
  background-color: #000;
  margin: 0 7px -1px 8px;
}
.work-detail .work-title .sub-info.wrap {
  gap: 20px;
  line-height: 25px;
  color: #666;
}
.work-detail .work-title .sub-info.wrap .date::after {
  display: none;
}
.work-cont .work-visual {
  width: 100%;
  height: 520px;
  background-color: #fff;
  margin-top: 70px;
  overflow: hidden;
  box-shadow: 2px 5px 40px 0 rgba(0, 0, 0, 0.08);
}
.work-cont .work-visual.h-auto {
  width: 100%;
  height: auto;
  margin: 70px auto;
}
.work-cont .work-visual img {
  width: 100%;
  object-fit: cover;
}
.work-cont .visual-detail {
  width: 630px;
  margin: 80px auto 0 auto;
  border-radius: 30px;
  height: 30rem;
  object-fit: cover;
  background-color: #e8e8e8;
  overflow: hidden;
  box-shadow: 2px 5px 40px 0 rgba(0, 0, 0, 0.08);
}
.work-cont .visual-detail img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.work-cont .visual-detail.login img {
  width: auto;
  transform: translateX(-210px);
}

.work-cont .visual-detail.mobile img {
  object-fit: contain;
}
.work-cont .visual-detail.logo img {
  object-fit: contain;
  background-color: #fff;
}
.work-cont .visual-detail.logo.cover img {
  object-fit: cover;
}
.work-cont .work-info {
  width: 630px;
  padding: 0 40px;
  margin: 80px auto 0 auto;
}
.work-cont .work-info p {
  color: #666;
  font-size: 1.1rem;
  line-height: 170%;
  text-align: center;
}
.back {
  display: block;
  width: 180px;
  margin: 120px auto 0;
  padding: 13px 24px 13px;
  border-radius: 30px;
  background: #eee;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
}
.work-cont .visual-detail.react_logo {
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
}
.work-cont .visual-detail.react_logo img {
  width: 40%;
}
.work-cont .visual-detail.dongalogo {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.work-cont .visual-detail.dongalogo img {
  width: 50%;
}

/* visible */
.effect {
  position: relative;
  transition: top 1s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s linear;
  opacity: 0;
  top: 50px;
}
.effect.visible {
  top: 0px;
  opacity: 1;
}
.works.effect {
  transition: top 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.4s linear;
}
.line .effect {
  opacity: 1;
  top: 0;
  background-image: linear-gradient(transparent calc(100% - 3px), #000 3px);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size 1s;
  background-image: linear-gradient(transparent 60%, #f8cd07 40%);
  transition-delay: 0.5s;
}
.line .effect.visible {
  background-size: 100% 100%;
}
.effect .highlight {
  opacity: 1;
  top: 0;
  background-image: linear-gradient(transparent calc(100% - 3px), #000 3px);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size 1s;
  background-image: linear-gradient(
    transparent 60%,
    rgba(248, 205, 7, 0.7) 40%
  );
  transition-delay: 0.5s;
}
.effect.visible .highlight {
  background-size: 100% 100%;
  font-weight: 700;
  color: #333;
}
.button-wrap {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.button-wrap a {
  margin: 120px 0;
}
.button-wrap a.project {
  display: block;
  width: 180px;
  margin: 120px 0;
  padding: 13px 24px 13px;
  border-radius: 30px;
  background: #333;
  color: #fff;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
}

/* 미디어 쿼리 */
/* 모바일 768px 미만 */
@media (max-width: 767px) {
  header {
    padding-top: 1rem;
  }
  .header-inner .header-top {
    padding: 10px 1.5rem;
  }
  .header-inner .header-top a {
    display: block;
    width: 100%;
    text-align: center;
  }
  .header-inner .header-top h3 {
    display: none;
  }
  .header-inner .header-bottom {
    font-size: 0.9rem;
    padding: 10px 1.5rem;
    text-align: center;
  }

  /* main */
  .main-inner {
    flex-direction: column-reverse;
    padding: 7rem 1.5rem 0 1.5rem;
  }
  .main-inner .main-left {
    width: 100%;
    margin-top: 2rem;
  }
  .main-inner .main-right {
    width: 100%;
    margin-left: 0;
  }
  .main-inner .main-right img {
    position: unset;
  }
  .main-inner .main-right:after {
    display: none;
  }
  .main-inner .main-left h2 {
    font-size: 1.2rem;
  }
  .main-inner .main-left h1 {
    font-size: 4rem;
    line-height: 130%;
  }
  .main-inner .main-left p {
    margin-top: 2rem;
    font-size: 1.1rem;
  }

  /* about */
  .about-cont {
    margin-bottom: 3rem;
  }
  .about-inner {
    margin-top: 2rem;
    padding: 0 1.5rem;
  }
  .about-inner .about-txt h3 {
    margin-bottom: 2rem;
  }
  .about-inner .about-txt p {
    font-size: 1.1rem;
  }
  .about-inner .about-bottom {
    display: block;
  }
  .about-inner .about-bottom > div {
    width: 100%;
  }
  .about-inner .about-edu ul {
    border: none;
    margin-right: 0;
  }
  .about-inner .about-bottom > div h3 {
    margin-bottom: 2rem;
  }
  .about-inner .about-bottom > div ul li {
    font-size: 1.1rem;
    margin-bottom: 1.3rem;
  }
  .about-inner .about-bottom > div ul li span {
    display: block;
    width: 90px;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }

  /* works */
  .works-inner {
    display: block;
    padding: 3rem 1.5rem;
  }
  .works-inner .works {
    width: 100%;
    margin-bottom: 2rem;
  }
  .works-inner .works:last-child {
    margin-bottom: 0;
  }
  .works-inner .works .works-title h3 {
    font-size: 1.5rem;
  }
  .works-inner .works .works-title p {
    margin-top: 1rem;
  }
  .works-inner .works .works-img {
    height: auto;
  }

  /* contact */
  .contact-cont {
    margin: 3rem 1.5rem;
  }
  .contact-cont .contact-inner {
    display: block;
  }
  .contact-cont .contact-inner .pc-txt {
    display: none;
  }
  .contact-cont .contact-inner .mobile-txt {
    display: block;
  }
  .contact-cont .contact-inner .mobile-txt p {
    font-size: 1.5rem;
    line-height: 130%;
    margin-bottom: 0.3rem;
  }
  .contact-cont .contact-inner a {
    font-size: 1.1rem;
  }

  /* work */
  .work-detail {
    width: auto;
    padding: 7rem 1.5rem 0 1.5rem;
  }
  .work-detail .work-title {
    width: auto;
    padding: 0;
  }
  .work-detail .work-title h3 {
    font-size: 1.2rem;
  }
  .work-detail .work-title h2 {
    font-size: 1.7rem;
    margin-top: 1rem;
  }
  .work-detail .work-title .sub-info {
    font-size: 1rem;
    margin-top: 1rem;
  }
  .work-cont .work-visual {
    height: auto;
    margin-top: 3rem;
  }
  .work-cont .work-info {
    width: auto;
    margin-top: 2rem;
    padding: 0;
  }
  .work-cont .work-info p {
    font-size: 1rem;
  }
  .work-cont .visual-detail {
    width: 90vw;
    height: auto;
    margin-top: 2rem;
  }
  .work-cont .visual-detail.mobile {
    width: 50vw;
  }
  .work-cont .visual-detail.logo {
    width: 50vw;
  }
  .back {
    margin-top: 3rem;
    padding: 10px 20px 10px;
    font-size: 1rem;
  }
}

/* 태블릿 768px ~ 1024px 사이 */
@media (min-width: 768px) and (max-width: 1024px) {
  /* main */
  .main-inner .main-left h2 {
    font-size: 1.5rem;
  }
  .main-inner .main-left h1 {
    font-size: 5rem;
  }
  .main-inner .main-left {
    width: 52%;
  }
  .main-inner .main-right {
    width: 40%;
  }
  .main-inner .main-right img {
    right: 10%;
  }
  .main-inner .main-right::after {
    top: 21%;
    height: 60%;
  }
  .main-inner .main-left p {
    width: 100%;
    font-size: 1.1rem;
    margin-top: 10%;
  }

  /* about */
  .about-inner .about-txt p {
    font-size: 1.1rem;
  }
  .about-inner .about-bottom > div ul li span {
    display: block;
    margin: 0 200px 10px 0;
  }
  .about-inner .about-bottom > div ul li {
    margin-bottom: 20px;
    font-size: 1.1rem;
  }

  /* work */
  .works-inner {
    display: block;
  }
  .works-inner .works {
    width: 100%;
    margin-bottom: 2rem;
  }
  .works-inner .works a {
    display: flex;
  }
  .works-inner .works a .works-title {
    width: 60%;
  }
  .works-inner .works .works-title h3 {
    font-size: 1.6rem;
  }
  .works-inner .works a .works-img {
    width: 40%;
    position: relative;
  }
  .works-inner .works a .works-img img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  /* contact */
  .contact-inner p {
    font-size: 1.6rem;
  }
  .contact-inner a {
    font-size: 1.1rem;
  }
}

/* 데스크탑 1024px 이상 */
@media (min-width: 1025px) {
}
